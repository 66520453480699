import {
  ColorPickerFieldValue,
  ColorPickerValue,
} from "@smartsuite/types/lib/field-values/color-picker";

import { ColorPickerFieldControl } from "./ColorPickerFieldControl/ColorPickerFieldControl";
import { FieldRegistryConfig } from "../../types";

export const defaultColorPickerValue = {
  value: "#000000",
  name: "",
};

export const colorPickerConfig: FieldRegistryConfig<ColorPickerFieldValue> = {
  icon: "color-picker",
  control: (props) => <ColorPickerFieldControl {...props} />,
  prefill: ({ value, field }) => {
    const displayFormat = field?.params.entries_allowed;
    const colors = value.split(",");

    const convertToHex = (colorStr: string): string => {
      const componentToHex = (c: number): string => {
        const hex = Math.round(c).toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      };

      const rgbToHex = (r: number, g: number, b: number): string => {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
      };

      if (colorStr.startsWith("#")) {
        return colorStr.toLowerCase();
      }

      if (colorStr.startsWith("rgb")) {
        const [r, g, b] = colorStr
          .replace("rgb(", "")
          .replace(")", "")
          .split(" ")
          .map((num) => parseInt(num.trim()));

        return rgbToHex(r, g, b);
      }

      if (colorStr.startsWith("cmyk")) {
        const [c, m, y, k] = colorStr
          .replace("cmyk(", "")
          .replace(")", "")
          .split(" ")
          .map((num) => parseFloat(num.trim().replace("%", "")) / 100);

        const r = 255 * (1 - c) * (1 - k);
        const g = 255 * (1 - m) * (1 - k);
        const b = 255 * (1 - y) * (1 - k);

        return rgbToHex(r, g, b);
      }

      return "";
    };

    const isValidHex = (colorPickerValue: ColorPickerValue): boolean =>
      /^#([0-9A-F]{6}|[0-9A-F]{3})$/i.test(colorPickerValue.value);

    const mapToColor = (color: string): ColorPickerValue => ({
      value: convertToHex(color),
      name: undefined,
    });

    if (displayFormat === "multiple" && colors.length) {
      return colors.map(mapToColor).filter(isValidHex);
    }

    return colors[0] ? [mapToColor(colors[0])].filter(isValidHex) : [];
  },
};
