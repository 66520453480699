import React, { FC, useMemo } from "react";
import * as yup from "yup";
import { InputGroup } from "@smartsuite/react-ui/lib";
import { TagsFieldValue } from "@smartsuite/types";

import { FormFieldProps } from "../../../types";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { useFormField } from "../../../../hooks/useFormField";
import { applyYupDefaults, validateWithYup } from "../../../validator";

import { TagsFieldSelect } from "./TagsFieldSelect/TagsFieldSelect";

export type TagsFieldControlProps = FormFieldProps;

export const TagsFieldControl: FC<TagsFieldControlProps> = ({
  name,
  label,
  caption,
  required,
  field,
  formItem,
  readOnly,
}) => {
  const allowedTags = useMemo(() => field.params.choices ?? [], [field.params.choices]);

  const formField = useFormField<TagsFieldValue>({
    name,
    validate: validateWithYup(applyYupDefaults(yup.array(), field, formItem)),
  });

  const handleOpenChange = (open: boolean): void => {
    open ? formField.onFocus() : formField.onBlur();
  };

  return (
    <FormFieldControl
      label={label}
      state={formField.state}
      caption={caption}
      required={required}
      errorMessage={formField.errorMessage}
      readOnly={readOnly}
    >
      <InputGroup state={formField.state}>
        <TagsFieldSelect
          readOnly={readOnly}
          tags={allowedTags}
          value={formField.value}
          onChangeValue={formField.onChange}
          onOpenedChange={handleOpenChange}
        />
      </InputGroup>
    </FormFieldControl>
  );
};
