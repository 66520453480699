import { FC, MouseEvent } from "react";
import * as yup from "yup";
import { AddButton, IconButton, InputGroup, Text } from "@smartsuite/react-ui/lib";
import { parseColor } from "@smartsuite/react-ui/lib/helpers/color";
import cx from "classnames";
import {
  ColorPickerDisplayFormat,
  ColorPickerFieldValue,
  ColorPickerValue,
  TextColorNames,
} from "@smartsuite/types";

import { FormFieldProps } from "../../../types";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { useFormField } from "../../../../hooks/useFormField";
import { ColorPickerFieldItem } from "./components/ColorPickerFieldItem/ColorPickerFieldItem";
import { applyYupDefaults, validateWithYup } from "../../../validator";

import "./ColorPickerFieldControl.sass";
import { ColorPickerFieldControlMessages } from "./ColorPickerFieldControl.text";
import { $t } from "../../../../utils/intl";

export type ColorPickerFieldControlProps = FormFieldProps;

export const ColorPickerFieldControl: FC<ColorPickerFieldControlProps> = ({
  name,
  label,
  field,
  caption,
  required,
  formItem,
  readOnly,
}) => {
  const formField = useFormField<ColorPickerFieldValue>({
    name,
    validate: validateWithYup(applyYupDefaults(yup.array(), field, formItem)),
  });

  const isMultiple = field.params.entries_allowed === "multiple";
  const displayFormat: ColorPickerDisplayFormat = field.params.display_format
    ? (field.params.display_format as ColorPickerDisplayFormat)
    : "hex";

  const handleRemoveItem = (index: number): void => {
    const newValue = [...formField.value];
    newValue.splice(index, 1);
    formField.onChange(newValue);
  };

  const handleChangeItem = (index: number, value: ColorPickerValue): void => {
    const newValue = [...formField.value];
    newValue[index] = value;
    formField.onChange(newValue);
  };

  const handleAddNewItem = (): void => {
    const newValue = formField.value ? [...formField.value, undefined] : [undefined];
    formField.onChange(newValue);
  };

  const handleFieldClick = (): void => {
    if (!isMultiple && !formField.value?.length) {
      handleAddNewItem();
    }
  };

  const fieldItemClassName = cx("", {
    "color-picker-field-control__single-input": !isMultiple,
  });

  return (
    <FormFieldControl
      label={label}
      state={formField.state}
      caption={caption}
      required={required}
      errorMessage={formField.errorMessage}
      readOnly={readOnly}
    >
      <InputGroup state={formField.state} onClick={handleFieldClick}>
        <div className="color-picker-field-control">
          {isMultiple && !readOnly && (
            <AddButton
              textStyle={{
                size: "xs",
                weight: "semibold",
                color: TextColorNames.primary,
                hoverColor: TextColorNames.primary,
              }}
              iconStyle={{ marginRight: 6 }}
              onClick={handleAddNewItem}
            />
          )}

          {!isMultiple && !formField.value?.length && (
            <Text color="inactive">
              {$t(ColorPickerFieldControlMessages.selectColorPlaceholder)}
            </Text>
          )}

          {formField.value?.map((value, index) => {
            const handleClear = (event?: MouseEvent): void => {
              event?.stopPropagation();
              handleRemoveItem(index);
            };

            const handleChange = (newValue: ColorPickerValue): void => {
              const color = parseColor(newValue.value);

              handleChangeItem(index, {
                value: color.hex(),
                name: newValue.name,
              });
            };

            return (
              <ColorPickerFieldItem
                key={index}
                className={fieldItemClassName}
                value={value}
                displayFormat={displayFormat}
                onClear={handleClear}
                onChange={handleChange}
                showClear={isMultiple}
                onOpenDropdown={formField.onFocus}
                onCloseDropdown={formField.onBlur}
              />
            );
          })}

          {!isMultiple && !!formField.value?.length && (
            <IconButton
              className="color-picker-field-control__clear"
              size="S"
              name="close"
              ariaLabel="remove"
              onClick={() => formField.onChange([])}
            />
          )}
        </div>
      </InputGroup>
    </FormFieldControl>
  );
};
