import { FC, useState, KeyboardEvent, ChangeEvent, FocusEvent, useRef } from "react";
import * as yup from "yup";
import {
  InputGroup,
  EmailPill,
  isEnterKey,
  isBackspaceKey,
  isDeleteKey,
} from "@smartsuite/react-ui/lib";
import cx from "classnames";

import { FormFieldProps } from "../../../types";
import { useFormField } from "../../../../hooks/useFormField";
import { EmailFieldValue } from "../email.config";
import { validateWithYup } from "../../../validator";
import { validatorText } from "../../../validator.text";
import { $t } from "../../../../utils/intl";

import "./MultipleEmailFieldControl.sass";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { noop } from "lodash-es";

export type EmailFieldControlProps = FormFieldProps;

export const MultipleEmailFieldControl: FC<EmailFieldControlProps> = ({
  formItem,
  name,
  required,
  label,
  caption,
  placeholder,
  readOnly,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState<string>("");

  const formField = useFormField<EmailFieldValue>({
    name,
    validate: validateWithYup(
      yup
        .array()
        .of(yup.string().email($t(validatorText.validationEmail)))
        .when({
          is: () => formItem.required,
          then: (s) => s.required($t(validatorText.validationRequired)),
        })
    ),
  });

  const handleAddEmail = (email: string): void => {
    if (formField.value?.includes(email)) return;

    if (formField.value?.length) {
      formField.onChange([...formField.value, email]);
    } else {
      formField.onChange([email]);
    }
  };

  const handleChange = (oldValue: string, newValue: string): void => {
    formField.onChange(formField.value.map((email) => (email === oldValue ? newValue : email)));
  };

  const handleRemoveEmail = (email: string): void => {
    formField.onChange(formField.value.filter((currentEmail) => currentEmail !== email));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (isEnterKey(event)) {
      event.preventDefault();

      const trimmedValue = inputValue.trim();

      if (trimmedValue) {
        handleAddEmail(trimmedValue);
        setInputValue("");
      }
    }
    if ((isBackspaceKey(event) || isDeleteKey(event)) && !inputValue && formField.value?.length) {
      handleRemoveEmail(formField.value[formField.value.length - 1]);
    }
  };

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>): void => {
    formField.onBlur(event);
    if (inputValue) {
      handleAddEmail(inputValue);
      setInputValue("");
    }
  };

  const handleChangeInputValue = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;

    setInputValue(newValue);

    if (newValue.trim() && newValue.charAt(newValue.length - 1) === " ") {
      handleAddEmail(newValue.trim());
      setInputValue("");
    }

    if (newValue.trim() && newValue.charAt(newValue.length - 1) === ",") {
      handleAddEmail(newValue.trim().slice(0, -1));
      setInputValue("");
    }
  };

  const handleInputGroupClick = (): void => {
    if (inputRef.current) {
      inputRef.current.focus();
      formField.onFocus();
    }
  };

  const inputClassNames = cx("email-field-control__input-multiple", {
    "email-field-control__input-multiple__not_empty": !!formField.value?.length,
  });

  return (
    <FormFieldControl
      caption={caption}
      errorMessage={formField.errorMessage}
      label={label}
      required={required}
      state={formField.state}
      readOnly={readOnly}
    >
      <InputGroup state={formField.state} onClick={handleInputGroupClick}>
        <div className="email-field-control__multiple">
          {formField.value?.map((email: string) => {
            return (
              <EmailPill
                key={email}
                initValue={email}
                isEditable
                onChange={handleChange}
                onRemove={handleRemoveEmail}
                onClick={noop}
              />
            );
          })}
          <input
            readOnly={readOnly}
            ref={inputRef}
            data-testid="input"
            value={inputValue}
            placeholder={!formField.value?.length ? placeholder : ""}
            className={inputClassNames}
            onKeyDown={handleKeyDown}
            onChange={handleChangeInputValue}
            onFocus={formField.onFocus}
            onBlur={handleInputBlur}
          />
        </div>
      </InputGroup>
    </FormFieldControl>
  );
};
