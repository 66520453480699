import { LinkedRecordObject } from "../../../types/form";
import { mapRecord } from "../../prefill/helpers";
import { FieldRegistryConfig } from "../../types";
import { LinkedRecordFieldControl } from "./LinkedRecordFieldControl/LinkedRecordFieldControl";

export type LinkedRecordFormValue = LinkedRecordObject & {
  linkedRecord: LinkedRecordObject;
};

export type LinkedRecordFieldValue = string[];

/**
 * NB: In order to allow comparisons of types "contains" and "does not contain" we need to
 * have access to the actual label of the selected choices. Traditionally, this field would
 * only hold an array of record IDs, but we need to be able to grab the record title in
 * comparison time.
 * This data is not accessible by default inside the comparison function because it would
 * require a query to the API to fetch the record title and access to context data. Instead,
 * we store the record title in the field choice value, within the 'linkedRecord' key.
 *
 * In the comparison, we read either the titles or the IDs, depending on the method.
 * When submitting the form, we send only the IDs.
 */
export const linkedRecordFieldConfig: FieldRegistryConfig<
  LinkedRecordFormValue[],
  LinkedRecordFieldValue
> = {
  icon: "linked-records-2",
  control: LinkedRecordFieldControl,
  convertFormValueToApi: (value) => value.map((v) => v.id.toString()),
  prefill: ({ value, field, linkedRecords }) => {
    const entriesAllowed = field.params.entries_allowed;
    const linkedRecordIDs = value.split(",");

    if (entriesAllowed === "multiple") {
      const choices = linkedRecords?.filter(
        (record) =>
          linkedRecordIDs.includes(record.id.toString()) || linkedRecordIDs.includes(record.title)
      );
      return choices?.map(mapRecord) ?? [];
    }

    const [firstId] = linkedRecordIDs;
    const choice = linkedRecords?.find(
      (record) => record.id.toString() === firstId || record.title === firstId
    );
    return choice ? [mapRecord(choice)] : [];
  },
};
