import { FieldRegistryConfig } from "../../types";
import { TextFieldValue } from "../text/text.config";
import { SignatureFieldControl } from "./SignatureFieldControl/SignatureFieldControl";

export interface SignatureFieldValue {
  image_base64?: string;
  text?: string;
}

export const isSignatureEmpty = (value: SignatureFieldValue): boolean => {
  return !value.image_base64 && !value.text;
};

export const signatureFieldConfig: FieldRegistryConfig<TextFieldValue> = {
  icon: "signature",
  control: SignatureFieldControl,
  prefill: ({ value }) => {
    return {
      image_base64: null,
      text: value,
    };
  },
};
