import { FieldVisibilityCallback } from "../../hooks/useFormFieldVisibility";
import { FieldControlComponent } from "../../registry/FieldControlComponent";
import { Fields } from "../../types/fields";
import { FormConfigApplicationStructureItem, FormConfigItem } from "../../types/form";

export interface FormFieldItemProps {
  appField?: FormConfigApplicationStructureItem;
  item: FormConfigItem;
  checkFieldVisibility: FieldVisibilityCallback;
}

export const FormFieldItem: React.FC<FormFieldItemProps> = ({
  appField,
  item,
  checkFieldVisibility,
}) => {
  if (!appField) return null;

  const isItemConditionSatisfied = checkFieldVisibility(item.slug);

  const shouldShowField =
    isItemConditionSatisfied ||
    [Fields.userfield, Fields.linkedrecordfield].includes(appField.field_type);

  if (!shouldShowField) return null;

  return (
    <div
      key={item.slug}
      className="form-field-item"
      data-testid="form-field-item"
      data-field-name={item.slug}
    >
      <FieldControlComponent
        formItem={item}
        field={appField}
        isItemConditionSatisfied={isItemConditionSatisfied}
      />
    </div>
  );
};
