import { z, ZodError } from "zod";

export const envVariables = z.object({
  REACT_APP_VERSION: z.string(),
  REACT_APP_NAME: z.string(),
  REACT_APP_SMARTSUITE_API_URL: z.string().min(1),
  REACT_APP_FILESTACK_API_KEY: z.string().min(1),
  REACT_APP_FILESTACK_CNAME: z.string().min(1),
  REACT_APP_MAXIMUM_UPLOAD_FILES: z.string().min(1),
  REACT_APP_MAXIMUM_UPLOAD_SIZE: z.string().min(1),
  REACT_APP_SENTRY_ENVIRONMENT: z.string(),
  REACT_APP_GOOGLE_MAPS_API_KEY: z.string().min(1),
  REACT_APP_RECAPTCHA_SITE_KEY: z.string().min(1),
});

const onValidationError = (error: ZodError): never => {
  console.error("❌ Invalid environment variables:", error.flatten().fieldErrors);
  throw new Error("Invalid environment variables");
};

const createEnv = (): z.infer<typeof envVariables> => {
  const runtimeEnv = process.env;

  const parsedEnv = envVariables.safeParse(runtimeEnv);

  if (!parsedEnv.success) {
    onValidationError(parsedEnv.error);
  }

  return runtimeEnv;
};

export const env = createEnv();

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    interface ProcessEnv extends z.infer<typeof envVariables> {}
  }
}
