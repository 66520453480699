import React, { FC, useState } from "react";
import PhoneInput, { Country, isValidPhoneNumber } from "react-phone-number-input";
import { IconButton, InputGroup, Paragraph } from "@smartsuite/react-ui/lib";
import { E164Number } from "libphonenumber-js/types";

import * as yup from "yup";
import { FormFieldProps } from "../../../types";
import { useFormField } from "../../../../hooks/useFormField";
import { PhoneFormValue } from "../phone.config";
import { applyYupDefaults, validateWithYup } from "../../../validator";
import { $t } from "../../../../utils/intl";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { fieldsText } from "../../fields.text";
import { AddPhone } from "./components/AddPhone";

import "./PhoneFieldControlMultiple.sass";
import { validatorText } from "../../../validator.text";

export type PhoneFieldControlMultipleProps = FormFieldProps;

export const PhoneFieldControlMultiple: FC<PhoneFieldControlMultipleProps> = ({
  caption,
  field,
  formItem,
  label,
  name,
  required,
  readOnly,
}) => {
  const [enteredValue, setEnteredValue] = useState<E164Number | undefined>("");

  const defaultCountry: Country = (field.params.default_country?.toUpperCase() as Country) ?? "";

  const formField = useFormField<PhoneFormValue>({
    name,
    validate: validateWithYup(
      applyYupDefaults(
        yup
          .array()
          .test("isValidEnteredPhoneNumber", " ", () => {
            return enteredValue ? isValidPhoneNumber(enteredValue) : true;
          })
          .of(
            yup.string().test("isValidPhoneNumber", " ", (value) => {
              return value ? isValidPhoneNumber(value) : true;
            })
          ),
        field,
        formItem
      )
    ),
  });

  const handleChangeValue = (index: number, value: E164Number | undefined): void => {
    const newValue = [...formField.value];
    newValue[index] = value ?? "";
    formField.onChange(newValue);
  };

  const handleAddNewPhone = (value: E164Number): void => {
    if (formField.value) {
      formField.onChange([...formField.value, value]);
    } else {
      formField.onChange([value]);
    }
  };

  const handleDeletePhone = (index: number): void => {
    const newValue = [...formField.value];
    newValue.splice(index, 1);
    formField.onChange(newValue);
  };

  const phones = Array.isArray(formField.value) ? formField.value : [];

  return (
    <FormFieldControl
      caption={caption}
      errorMessage={formField.errorMessage}
      label={label}
      required={required}
      state={formField.state}
      readOnly={readOnly}
    >
      <div className="phone-field-control-multiple">
        {phones.map((value, index) => {
          const isValueValid = isValidPhoneNumber(value);

          return (
            <div key={index}>
              <InputGroup state={isValueValid ? "default" : "error"}>
                <PhoneInput
                  disabled={readOnly}
                  countryCallingCodeEditable={false}
                  defaultCountry={defaultCountry}
                  placeholder={$t(fieldsText.phonePlaceholder)}
                  value={value}
                  onBlur={formField.onBlur}
                  onChange={(newValue) => handleChangeValue(index, newValue)}
                  onFocus={formField.onFocus}
                />
                <IconButton
                  className="phone-field-control-multiple__delete_button"
                  name="close"
                  ariaLabel="delete"
                  onClick={() => handleDeletePhone(index)}
                />
              </InputGroup>

              {!isValueValid && (
                <div className="phone-field-control-multiple__error-message">
                  <Paragraph color="error" size="s">
                    {$t(validatorText.validationPhone)}
                  </Paragraph>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <AddPhone
        readOnly={readOnly}
        defaultCountry={defaultCountry}
        onAddNewPhone={handleAddNewPhone}
        forceAdding={!formField.value?.length}
        onChangeEnteredValue={setEnteredValue}
      />
    </FormFieldControl>
  );
};
