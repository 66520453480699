import { FieldRegistryConfig } from "../../types";
import { MultipleSelectFieldControl } from "./MultipleSelectFieldControl/MultipleSelectFieldControl";

export type MultipleSelectFieldValue = string[];

export const multipleSelectFieldConfig: FieldRegistryConfig<MultipleSelectFieldValue> = {
  icon: "multiple-select",
  control: MultipleSelectFieldControl,
  prefill: ({ value, field }) => {
    const choices = field.params.choices;
    const choiceIDs = value.split(",");
    const selectedChoices = choices?.filter(
      (choice) => choiceIDs.includes(choice.value) || choiceIDs.includes(choice.label)
    );
    return selectedChoices?.map((choice) => choice.value) ?? [];
  },
};
