import { ChangeEvent, FC, useRef, useState } from "react";
import {
  formatDisplayDuration,
  convertInputDuration,
} from "@smartsuite/fields-logic/lib/utils/duration";
import { isEnterKey, TextFieldGroup } from "@smartsuite/react-ui/lib";
import * as yup from "yup";

import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { FormFieldProps } from "../../../types";
import { useFormField } from "../../../../hooks/useFormField";
import { validateWithYup } from "../../../validator";
import { $t } from "../../../../utils/intl";
import { validatorText } from "../../../validator.text";
import { DurationFieldValue } from "../duration.config";

export type DurationFieldControlProps = FormFieldProps;

export const DurationFieldControl: FC<DurationFieldControlProps> = ({
  name,
  label,
  formItem,
  field,
  caption,
  placeholder,
  required,
  readOnly,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const formField = useFormField<DurationFieldValue>({
    name,
    validate: validateWithYup(
      yup.number().when({
        is: () => formItem.required,
        then: (number) => number.moreThan(0, $t(validatorText.validationRequired)),
      })
    ),
  });

  const [enteredValue, setEnteredValue] = useState(
    formatDisplayDuration(
      formField.value,
      field?.params?.display_format ?? "compact",
      field?.params?.duration_format ?? "hh:mm"
    )
  );

  const handleEditEnd = (): void => {
    const newDuration = convertInputDuration(enteredValue);

    formField.onChange(newDuration);

    setEnteredValue(
      formatDisplayDuration(
        newDuration,
        field?.params?.display_format ?? "compact",
        field?.params?.duration_format ?? "hh:mm"
      )
    );
    formField.onBlur();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    formField.onChange(1);
    setEnteredValue(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    if (isEnterKey(event)) {
      event.preventDefault();
      handleEditEnd();
      inputRef.current?.blur();
    }
  };

  return (
    <FormFieldControl
      label={label}
      state={formField.state}
      caption={caption}
      required={required}
      errorMessage={formField.errorMessage}
      readOnly={readOnly}
    >
      <TextFieldGroup
        ref={inputRef}
        inputGroupState={formField.state}
        value={enteredValue}
        onChange={handleChange}
        onBlur={handleEditEnd}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        onFocus={formField.onFocus}
        readOnly={readOnly}
      />
    </FormFieldControl>
  );
};
