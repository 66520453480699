import React, { createContext, useContext } from "react";
import { FormConfig, FormConfigApplicationStructureItem } from "../types/form";

import {
  extractFieldName,
  findFieldSlug,
  getFormLabelsBySlug,
  isDynamicField,
  isPrefillKey,
  normalizePrefillFieldValue,
} from "../helpers/prefill";

interface PrefillContextType {
  prefillValues: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    simpleFields: Record<string, any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dynamicFields: Record<string, any>;
  };
  config?: FormConfig;
}

interface PrefillProviderProps {
  applicationStructure: FormConfigApplicationStructureItem[];
  config: FormConfig;
  children: React.ReactNode;
}

export const PrefillContext = createContext<PrefillContextType>({
  prefillValues: {
    simpleFields: {},
    dynamicFields: {},
  },
});

export const PrefillProvider = ({
  applicationStructure,
  children,
  config,
}: PrefillProviderProps): React.ReactElement => {
  const query = new URLSearchParams(window.location.search);

  const prefillValues = Array.from(query.entries()).reduce(
    (acc, [key, value]) => {
      if (!isPrefillKey(key)) return acc;

      const fieldName = extractFieldName(key);
      const formLabelsBySlug = getFormLabelsBySlug(config, applicationStructure);
      const fieldSlug = findFieldSlug(formLabelsBySlug, fieldName);
      const field = applicationStructure.find((field) => field.slug === fieldSlug);

      if (!field) return acc;

      if (isDynamicField(field.field_type)) {
        return {
          ...acc,
          dynamicFields: { ...acc.dynamicFields, [field.slug]: value },
        };
      }

      const normalizedValue = normalizePrefillFieldValue({
        value,
        field,
        config,
      });

      return {
        ...acc,
        simpleFields: { ...acc.simpleFields, [field.slug]: normalizedValue },
      };
    },
    {
      simpleFields: {},
      dynamicFields: {},
    }
  );

  return (
    <PrefillContext.Provider value={{ prefillValues, config }}>{children}</PrefillContext.Provider>
  );
};

export const usePrefillContext = (): PrefillContextType => {
  return useContext(PrefillContext);
};
