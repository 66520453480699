import { FieldRegistryConfig } from "../../types";
import { RatingDisplayFormat } from "@smartsuite/types";
import { RatingFieldControl } from "./RatingFieldControl/RatingFieldControl";

export type RatingFieldValue = {
  color: string;
  display_format: RatingDisplayFormat;
  scale: number;
};

export const ratingFieldConfig: FieldRegistryConfig<RatingFieldValue> = {
  icon: "rating-field",
  control: RatingFieldControl,
  prefill: ({ value, field }) => {
    const scale = field.params.scale;
    if (!scale || !value) return null;

    return Math.max(0, Math.min(Number(value), scale));
  },
};
