import React, { FC, useRef, useState } from "react";
import {
  AddButton,
  ClickOutside,
  isEnterKey,
  Paragraph,
  TextFieldGroup,
} from "@smartsuite/react-ui/lib";
import { ipv4Ipv6Regex, TextColorNames } from "@smartsuite/types";
import cx from "classnames";

import "./AddIPAddress.sass";
import { $t } from "../../../../../../../../utils/intl";
import { AddIPAddressText } from "./AddIPAddress.text";
import { validatorText } from "../../../../../../../validator.text";

export interface AddIPAddressProps {
  onAddNewAddress: (value: string) => void;
  forceAdding?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  onChangeEnteredValue?: (value: string) => void;
}

export const AddIPAddress: FC<AddIPAddressProps> = ({
  readOnly,
  onAddNewAddress,
  forceAdding,
  placeholder,
  onChangeEnteredValue,
}) => {
  const inputRef = useRef(null);

  const [isAdding, setIsAdding] = useState(false);
  const [value, setValue] = useState<string>("");
  const [showError, setShowError] = useState(false);

  const addIPAddress = (): void => {
    if (!showError) {
      if (value) {
        onAddNewAddress(value);
        setValue("");
      }

      setIsAdding(false);
    }
  };

  const setAddingMode = (): void => {
    setIsAdding(true);

    setTimeout(() => {
      if (inputRef.current) {
        const input = inputRef.current as HTMLInputElement;
        input.focus();
      }
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (isEnterKey(event)) {
      event.preventDefault();
      addIPAddress();
    }
  };

  const handleChange = (newValue: string): void => {
    if (newValue?.length) {
      setValue(newValue);
      setShowError(!ipv4Ipv6Regex.test(newValue));
    } else {
      setValue("");
      setShowError(false);
    }

    if (onChangeEnteredValue) {
      onChangeEnteredValue(newValue);
    }
  };

  const inputClassName = cx("", {
    "add-ip-address-element": !forceAdding,
  });

  return (
    <ClickOutside onClickOutside={addIPAddress}>
      {showError || isAdding || forceAdding ? (
        <div>
          <div className={inputClassName}>
            <TextFieldGroup
              readOnly={readOnly}
              ref={inputRef}
              inputGroupState={showError ? "error" : "default"}
              placeholder={placeholder}
              value={value}
              onKeyDown={handleKeyDown}
              onChange={(event) => handleChange(event.target.value)}
            />
          </div>
          {showError && (
            <div className=".add-ip-address-element__error-message">
              <Paragraph color="error" size="s">
                {$t(validatorText.validationIPAddress)}
              </Paragraph>
            </div>
          )}
        </div>
      ) : (
        <AddButton
          className="add-ip-address-element"
          textStyle={{
            size: "xs",
            weight: "semibold",
            color: TextColorNames.primary,
            hoverColor: TextColorNames.primary,
          }}
          iconStyle={{ marginRight: 6 }}
          onClick={setAddingMode}
        >
          {$t(AddIPAddressText.addIPAddress)}
        </AddButton>
      )}
    </ClickOutside>
  );
};
