import { FormikHelpers, FormikValues } from "formik";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { saveFormRecord } from "../../api";
import { Form } from "../../components/Form/Form";
import { FormSubmittedMessage } from "../../components/FormSubmittedMessage/FormSubmittedMessage";
import { clearCheckpoint } from "../../helpers/checkpoints";
import { getFormattedRedirectUrl } from "../../helpers/structure";
import { FormContext, FormContextValue } from "../../hooks/useFormContext";
import { convertFormValuesToApi } from "../../registry/converter";
import { FormConfig, FormValues } from "../../types/form";
import { PrefillProvider } from "../../hooks/usePrefillContext";

export interface FormDetailProps {
  accountId: string;
  config: FormConfig;
  hash: string;
  isPreview?: boolean;
}

export const FormDetail: React.FC<FormDetailProps> = ({ accountId, config, hash, isPreview }) => {
  const formState = config?.form_state;
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleOnSubmit = async (
    values: FormValues,
    formik: FormikHelpers<FormikValues>
  ): Promise<void> => {
    formik.setStatus(null);

    try {
      const record = convertFormValuesToApi(values, config);
      await saveFormRecord(accountId, hash, record, !!isPreview);
      if (formState?.has_progress_restore) clearCheckpoint(accountId, hash);

      if (formState?.redirect_to_url) {
        window.location.href = getFormattedRedirectUrl(formState?.redirect_to_url);
        window.parent.postMessage(`redirect: ${formState?.redirect_to_url}`, "*");
        return;
      }

      setSubmitted(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.response?.status === 400) {
        formik.setErrors(error?.response?.data);
      } else if (error?.response?.status === 404) {
        navigate("../", { replace: true });
      } else {
        formik.setStatus("error");
      }
    }

    return await Promise.resolve();
  };

  const context: FormContextValue = useMemo(() => ({ config, accountId }), [config, accountId]);

  return (
    <FormContext.Provider value={context}>
      <PrefillProvider applicationStructure={config?.application?.structure} config={config}>
        {submitted ? (
          <FormSubmittedMessage config={config} onSubmitAnother={() => setSubmitted(false)} />
        ) : (
          <Form accountId={accountId} hash={hash} config={config} onSubmit={handleOnSubmit} />
        )}
      </PrefillProvider>
    </FormContext.Provider>
  );
};
