import { handleChoiceField } from "../../prefill/helpers";
import { FieldRegistryConfig } from "../../types";
import { SingleSelectFieldControl } from "./SingleSelectFieldControl/SingleSelectFieldControl";

export type SingleSelectFieldValue = string;

export const singleSelectFieldConfig: FieldRegistryConfig<SingleSelectFieldValue> = {
  icon: "single-select",
  control: SingleSelectFieldControl,
  prefill: ({ value, field }) => handleChoiceField(value, field.params.choices),
};
