import { $t } from "../../../utils/intl";
import { FieldRegistryConfig } from "../../types";
import { fieldsText } from "../fields.text";
import { MultipleEmailFieldControl } from "./MultipleEmailFieldControl/MultipleEmailFieldControl";
import { TextFieldControl } from "../text/TextFieldControl/TextFieldControl";
import { isArrayValue } from "@smartsuite/fields-logic/lib/safe-field-value/type-checking/arrays";

export type EmailFieldValue = string[];

export const emailFieldConfig: FieldRegistryConfig<EmailFieldValue, EmailFieldValue> = {
  icon: "email",
  control: (props) =>
    props.field.params?.entries_allowed === "multiple" ? (
      <MultipleEmailFieldControl placeholder={$t(fieldsText.emailPlaceholder)} {...props} />
    ) : (
      <TextFieldControl placeholder={$t(fieldsText.emailPlaceholder)} type="email" {...props} />
    ),
  convertFormValueToApi: (value) => (isArrayValue(value) ? value : [value]),
  prefill: ({ value, field }) => {
    const displayFormat = field?.params.entries_allowed;
    const emails = value.split(",");

    if (displayFormat === "single" && emails.length) {
      return emails[0];
    }

    if (displayFormat === "multiple" && emails.length) {
      return emails;
    }

    return null;
  },
};
