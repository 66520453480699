import { $t } from "../../../utils/intl";
import { FieldRegistryConfig } from "../../types";
import { fieldsText } from "../fields.text";
import { TimeFieldControl } from "./TimeFieldControl/TimeFieldControl";
import { convertTimeTo24HourFormat } from "./time.helpers";

export const MAX_TIME_LENGTH = 11; // "00:00:00 AM" = 11 characters;

export type TimeFieldValue = string;

export const timeFieldConfig: FieldRegistryConfig<TimeFieldValue> = {
  icon: "time",
  control: (props) => <TimeFieldControl placeholder={$t(fieldsText.timePlaceholder)} {...props} />,
  convertFormValueToApi: (value) => {
    return convertTimeTo24HourFormat(value);
  },
  prefill: ({ value }) => {
    const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    if (!timeRegex.test(value)) return null;

    return value;
  },
};
