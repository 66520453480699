import { ID, SimpleRecord } from "@smartsuite/types";

export const handleTextField = ({ value }: { value: string }): string => value;

export const handleNumberField = ({ value }: { value: string }): number | null => {
  const number = Number(value);
  return isNaN(number) ? null : number;
};

export const handleChoiceField = (
  value: string,
  choices?: Array<{ value: string; label: string }>
): string | null => {
  const choice = choices?.find((c) => c.value === value || c.label === value);
  return choice?.value ?? null;
};

export const mapRecord = (record: SimpleRecord): { id: ID; title: string } => ({
  id: record.id,
  title: record.title,
});
