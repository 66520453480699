import { useFormikContext } from "formik";
import { lazy, Suspense } from "react";
import * as yup from "yup";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { maximumUploadFiles, maximumUploadSize } from "../../../../config/env";
import { useFormField } from "../../../../hooks/useFormField";
import { SharedFile } from "../../../../types/file";
import { $t } from "../../../../utils/intl";
import { FormFieldProps } from "../../../types";
import { validateWithYup } from "../../../validator";
import { validatorText } from "../../../validator.text";

const FileUpload = lazy(
  () => import(/* webpackChunkName: "file-upload" */ "../../../../components/FileUpload/FileUpload")
);

const ONE_MB_IN_BYTES = 1024 * 1024;
const BYTES_PAD = 1; // 1 byte as padding because FileStack treats the value as "less than".

export type FileFieldControlProps = FormFieldProps;

export const FileFieldControl: React.FunctionComponent<FileFieldControlProps> = ({
  formItem,
  name,
  label,
  required,
  caption,
  readOnly,
}) => {
  const requiredText = $t(validatorText.validationRequired);
  const maxUploadFiles = formItem.params.maxUploadFiles ?? maximumUploadFiles;
  const maxUploadSize = formItem.params.maxUploadSize
    ? formItem.params.maxUploadSize * ONE_MB_IN_BYTES
    : maximumUploadSize; // default 10 MB

  const formField = useFormField({
    name,
    validate: validateWithYup(
      yup
        .array()
        .of(yup.object())
        .max(maxUploadFiles, $t(validatorText.validationMaxFiles, { max: maxUploadFiles }))
        .when({
          is: () => formItem.required,
          then: (s) => s.required(requiredText).min(1, requiredText),
        })
    ),
  });

  const formik = useFormikContext();

  const handleChange = (files: SharedFile[]): void => {
    formik.setFieldTouched(name);
    formik.setFieldValue(name, files);
  };

  return (
    <FormFieldControl
      label={label}
      caption={caption}
      required={required}
      state={formField.state}
      errorMessage={formField.errorMessage}
      readOnly={readOnly}
    >
      <Suspense fallback={null}>
        <FileUpload
          readOnly={readOnly}
          files={formField.value}
          maxUploadFiles={maxUploadFiles}
          maxUploadSize={maxUploadSize + BYTES_PAD}
          onChange={handleChange}
        />
      </Suspense>
    </FormFieldControl>
  );
};
