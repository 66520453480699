import { FieldRegistryConfig } from "../../types";
import { TagsFieldValue } from "@smartsuite/types";
import { TagsFieldControl } from "./TagsFieldControl/TagsFieldControl";

export const tagFieldConfig: FieldRegistryConfig<TagsFieldValue> = {
  icon: "tag",
  control: (props) => <TagsFieldControl {...props} />,
  prefill: ({ value, config }) => {
    const tagsIds = value.split(",");
    const selectedTags = config?.tags?.filter(
      (tag) => tagsIds.includes(tag.id.toString()) || tagsIds.includes(tag.label)
    );

    if (!selectedTags?.length) return [];

    return selectedTags.map((tag) => tag.id);
  },
};
