import { IpAddress } from "@smartsuite/types";

import { FieldRegistryConfig } from "../../types";
import { IPAddressFieldControlMultiple } from "./IPAddressFieldControl/components/IPAddressFieldControlMultiple/IPAddressFieldControlMultiple";
import { IPAddressFieldControlSingle } from "./IPAddressFieldControl/components/IPAddressFieldControlSingle/IPAddressFieldControlSingle";
import { $t } from "../../../utils/intl";
import { fieldsText } from "../fields.text";

export type IpAddressValue = IpAddress[];

export const ipAddressConfig: FieldRegistryConfig<IpAddressValue> = {
  icon: "ip-address",
  control: (props) =>
    props.field.params.entries_allowed === "single" ? (
      <IPAddressFieldControlSingle {...props} placeholder={$t(fieldsText.IPAddressPlaceholder)} />
    ) : (
      <IPAddressFieldControlMultiple {...props} placeholder={$t(fieldsText.IPAddressPlaceholder)} />
    ),
  prefill: ({ value, field }) => {
    const displayFormat = field?.params.entries_allowed;
    const ips = value.split(",");
    const mapToIpAddress = (ip: string): IpAddress => ({ address: ip, country_code: "" });

    if (displayFormat === "multiple" && ips.length) {
      return ips.map(mapToIpAddress);
    }

    return ips[0] ? [mapToIpAddress(ips[0])] : [];
  },
};
