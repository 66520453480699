import {
  InputGroup,
  stylesSwitchButtonRect,
  SwitchButton,
  TextSizes,
} from "@smartsuite/react-ui/lib";
import { useFormikContext } from "formik";
import { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { useCombinedFieldState } from "../../../../hooks/useCombinedFieldState";
import { useFormField } from "../../../../hooks/useFormField";
import { $t } from "../../../../utils/intl";
import { FormFieldProps } from "../../../types";
import { validateWithYup } from "../../../validator";
import { signatureText } from "../signature.text";
import { SignaturePadInput, SignaturePadInputActionsRef } from "./SignaturePadInput";

import { validatorText } from "../../../validator.text";
import "./SignatureFieldControl.sass";

export type SignatureFieldControlProps = FormFieldProps;

type SignatureInputMode = "draw" | "type";

export const SignatureFieldControl: React.FunctionComponent<SignatureFieldControlProps> = ({
  formItem,
  name,
  label,
  required,
  caption,
  readOnly,
}) => {
  const [mode, setMode] = useState<SignatureInputMode>("draw");
  const signaturePadRef = useRef<SignaturePadInputActionsRef>(null);
  const formik = useFormikContext();

  const requiredText = $t(validatorText.validationRequired);
  const textField = useFormField<string>({
    name: `${name}.text`,
    validate: validateWithYup(
      yup
        .string()
        .nullable()
        .when({
          is: () => formItem.required && mode === "type",
          then: (s) => s.required(requiredText),
        })
    ),
  });
  const drawField = useFormField({
    name: `${name}.image_base64`,
    validate: validateWithYup(
      yup
        .string()
        .nullable()
        .when({
          is: () => formItem.required && mode === "draw",
          then: (s) => s.required(requiredText),
        })
    ),
  });

  const { errorMessage, state } = useCombinedFieldState([textField, drawField]);

  const handleChangeMode = (modeValue: string): void => {
    handleClear();
    setMode(modeValue as SignatureInputMode);
  };

  const handleClear = (): void => {
    signaturePadRef.current?.clear();
    drawField.onChange(null);
    textField.onChange(null);

    setTimeout(() => {
      // needs to trigger validation in a different tick to correctly synchronize form state with updated
      // fields's validation functions due to `mode` state change
      void formik.validateForm();
    });
  };

  // In case the component gets re-mounted (e.g. when this field's visibility is back ON),
  // we need to re-set the mode and the value of the signature pad/input.
  useEffect(() => {
    if (drawField.value) {
      setMode("draw");
      setTimeout(() => {
        signaturePadRef.current?.setValue(drawField.value);
      });
    } else if (textField.value) {
      setMode("type");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEmpty = !textField.value && !drawField.value;

  return (
    <FormFieldControl
      label={label}
      caption={caption}
      required={required}
      state={state}
      errorMessage={errorMessage}
      readOnly={readOnly}
    >
      <InputGroup state={state}>
        <div className="signature-field-control__wrapper" hidden={mode !== "draw"}>
          <SignaturePadInput
            actionsRef={signaturePadRef}
            onChange={drawField.onChange}
            onBlur={drawField.onBlur}
            onFocus={drawField.onFocus}
          />
        </div>

        <div className="signature-field-control__wrapper" hidden={mode !== "type"}>
          <input
            type="text"
            name={textField.name}
            value={textField.value ?? ""}
            placeholder={$t(signatureText.placeholder)}
            className="signature-field-control__text-input"
            onChange={textField.onChange}
            onFocus={textField.onFocus}
            onBlur={textField.onBlur}
          />
        </div>
      </InputGroup>

      {!readOnly && (
        <div className="signature-field-control__footer">
          <SwitchButton
            currentValue={mode}
            options={[
              { value: "draw", label: $t(signatureText.drawMode) },
              { value: "type", label: $t(signatureText.typeMode) },
            ]}
            onChange={handleChangeMode}
            styles={{
              ...stylesSwitchButtonRect,
              gapItem: 8,
              textSize: TextSizes.XS,
            }}
          />

          {!isEmpty && (
            <button type="button" className="signature-field-control__clear" onClick={handleClear}>
              {$t(signatureText.clear)}
            </button>
          )}
        </div>
      )}
    </FormFieldControl>
  );
};
