import React, { ChangeEvent, FC } from "react";
import * as yup from "yup";

import { FormFieldProps } from "../../../../../types";
import { useFormField } from "../../../../../../hooks/useFormField";
import { IpAddressValue } from "../../../ip-address.config";
import { applyYupDefaults, validateWithYup } from "../../../../../validator";
import { FormFieldControl } from "../../../../../../components/FormFieldControl/FormFieldControl";
import { TextFieldGroup } from "@smartsuite/react-ui/lib";
import { ipv4Ipv6Regex } from "@smartsuite/types";
import { $t } from "../../../../../../utils/intl";
import { validatorText } from "../../../../../validator.text";

type IPAddressFieldControlSingleProps = FormFieldProps;

export const IPAddressFieldControlSingle: FC<IPAddressFieldControlSingleProps> = ({
  label,
  name,
  field,
  formItem,
  required,
  caption,
  placeholder,
  readOnly,
}) => {
  const formField = useFormField<IpAddressValue>({
    name,
    validate: validateWithYup(
      applyYupDefaults(
        yup.array().of(
          yup.object().test("isValidIpAddress", $t(validatorText.validationIPAddress), (value) => {
            return value ? ipv4Ipv6Regex.test(value.address) : true;
          })
        ),
        field,
        formItem
      )
    ),
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    formField.onChange(
      event?.target?.value
        ? [
            {
              address: event.target.value,
            },
          ]
        : []
    );
  };

  return (
    <FormFieldControl
      label={label}
      state={formField.state}
      caption={caption}
      required={required}
      errorMessage={formField.errorMessage}
      readOnly={readOnly}
    >
      <TextFieldGroup
        readOnly={readOnly}
        disabled={readOnly}
        inputGroupState={formField.state}
        name={formField.name}
        placeholder={placeholder}
        required={required}
        value={formField.value?.[0]?.address}
        onBlur={formField.onBlur}
        onChange={handleChange}
        onFocus={formField.onFocus}
      />
    </FormFieldControl>
  );
};
