import PhoneInput, { Country, isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import * as yup from "yup";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { useFormField } from "../../../../hooks/useFormField";
import { $t } from "../../../../utils/intl";
import { FormFieldProps } from "../../../types";
import { applyYupDefaults, validateWithYup } from "../../../validator";
import { validatorText } from "../../../validator.text";
import { fieldsText } from "../../fields.text";
import { InputGroup } from "@smartsuite/react-ui/lib";

import "./PhoneFieldControlSingle.sass";
import { E164Number } from "libphonenumber-js/types";
import { PhoneFormValue } from "../phone.config";

export type PhoneFieldControlSingleProps = FormFieldProps;

export const PhoneFieldControlSingle: React.FunctionComponent<PhoneFieldControlSingleProps> = ({
  caption,
  field,
  formItem,
  label,
  name,
  required,
  readOnly,
}) => {
  const formField = useFormField<PhoneFormValue>({
    name,
    validate: validateWithYup(
      applyYupDefaults(
        yup.array().of(
          yup.string().test("isValidPhoneNumber", $t(validatorText.validationPhone), (value) => {
            return value ? isValidPhoneNumber(value) : true;
          })
        ),
        field,
        formItem
      )
    ),
  });

  const defaultCountry: Country = (field.params.default_country?.toUpperCase() as Country) ?? "";

  const handleChange = (value: E164Number | undefined): void => {
    formField.onChange(value ? [value] : []);
  };

  return (
    <FormFieldControl
      caption={caption}
      errorMessage={formField.errorMessage}
      label={label}
      required={required}
      state={formField.state}
      readOnly={readOnly}
    >
      <InputGroup state={formField.state}>
        <PhoneInput
          disabled={readOnly}
          countryCallingCodeEditable={false}
          defaultCountry={defaultCountry}
          name={formField.name}
          placeholder={$t(fieldsText.phonePlaceholder)}
          value={formField.value?.[0]}
          onBlur={formField.onBlur}
          onChange={handleChange}
          onFocus={formField.onFocus}
        />
      </InputGroup>
    </FormFieldControl>
  );
};
