import axios from "axios";
import { SharedFile, SharedFileInput } from "./types/file";
import { BaseRecord, FormConfig, Member } from "./types/form";
import { AppField, Filter, SimpleRecord } from "@smartsuite/types";
import { env } from "./utils/env";

function baseUrl(): string {
  return env.REACT_APP_SMARTSUITE_API_URL;
}

export async function fetchFormConfig(
  accountId: string,
  hash: string,
  isPreview: boolean = false
): Promise<FormConfig> {
  return await axios
    .get(`${baseUrl()}/api/v1/forms/${hash}`, {
      headers: { "ACCOUNT-ID": accountId },
      params: {
        isPreview,
      },
    })
    .then((res) => res.data);
}

export async function saveFormRecord(
  accountId: string,
  hash: string,
  record: BaseRecord,
  isPreview: boolean = false
): Promise<void> {
  return await axios
    .post(`${baseUrl()}/api/v1/forms/${hash}/create_record/`, record, {
      headers: { "ACCOUNT-ID": accountId },
      params: {
        isPreview,
      },
    })
    .then((res) => res.data);
}

export async function createSharedFiles(
  accountId: string,
  hash: string,
  files: SharedFileInput[],
  isPreview: boolean = false
): Promise<SharedFile[]> {
  return await axios
    .post(`${baseUrl()}/api/v1/forms/${hash}/create_shared_files/`, files, {
      headers: { "ACCOUNT-ID": accountId },
      params: {
        isPreview,
      },
    })
    .then((res) => res.data);
}

export async function getLinkedRecords(
  accountId: string,
  hash: string,
  appId: string,
  fieldSlug: string,
  filter: Filter | null = null,
  isPreview: boolean = false
): Promise<SimpleRecord[]> {
  return await axios
    .post(
      `${baseUrl()}/api/v1/forms/${hash}/linked_records/`,
      {
        app: appId,
        slug: fieldSlug,
        filter_data: filter,
        isPreview,
      },
      {
        headers: { "ACCOUNT-ID": accountId },
        params: {
          isPreview,
        },
      }
    )
    .then((res) => res.data.map((record: SimpleRecord) => ({ ...record, id: record._id })));
}

export async function getLinkedApplicationFields(
  accountId: string,
  linkedAppId: string,
  hash: string,
  isPreview: boolean = false
): Promise<{ [key: string]: AppField }> {
  return await axios
    .get(
      `${baseUrl()}/api/v1/forms/${hash}/linked_application_fields/?linked_app_id=${linkedAppId}`,
      {
        headers: { "ACCOUNT-ID": accountId },
        params: {
          isPreview,
        },
      }
    )
    .then((res) => res.data);
}

type GetRelatedRecordsParams = {
  accountId: string;
  linkedAppId: string;
  hash: string;
  visibleFields: string[];
  isPreview?: boolean;
};

export async function getRelatedRecords({
  accountId,
  linkedAppId,
  hash,
  visibleFields,
  isPreview = false,
}: GetRelatedRecordsParams): Promise<BaseRecord[]> {
  return await axios
    .get(`${baseUrl()}/api/v1/forms/${hash}/related_records/?linked_app_id=${linkedAppId}`, {
      headers: { "ACCOUNT-ID": accountId },
      params: {
        isPreview,
        visible_fields: visibleFields,
      },
    })
    .then((res) => res.data);
}

export async function getAssignedToMembers(
  accountId: string,
  hash: string,
  fieldSlug: string,
  isPreview: boolean = false
): Promise<Member[]> {
  return await axios
    .get(`${baseUrl()}/api/v1/forms/${hash}/assigned_to_values/?slug=${fieldSlug}`, {
      headers: { "ACCOUNT-ID": accountId },
      params: {
        isPreview,
      },
    })
    .then((res) => res.data);
}
