import { useJsApiLoader } from "@react-google-maps/api";
import { KeyValueCustomSelect, PlainTextField } from "@smartsuite/react-ui/lib";

import { useMemo } from "react";
import * as yup from "yup";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { useCombinedFieldState } from "../../../../hooks/useCombinedFieldState";
import { UseFormFieldResult, useFormField } from "../../../../hooks/useFormField";
import { generateTranslatedCountriesListWithCodes } from "../../../../utils/countriesList";
import { $t } from "../../../../utils/intl";
import { FormFieldProps } from "../../../types";
import { AddressFieldControlMessages } from "./AddressFieldControl.text";

import { getGeocode, getLatLng } from "use-places-autocomplete";
import { FieldChoice } from "../../../../types/form";
import { validateWithYup } from "../../../validator";
import { validatorText } from "../../../validator.text";
import { AutocompleteAddressField } from "../AutocompleteAddressField/AutocompleteAddressField";
import { GOOGLE_API_LIBRARIES } from "../address.constants";
import { addressFieldValueToSysRoot, getParsedAddressComponents } from "../address.helpers";
import { AddressFieldTypes, AddressFormValue } from "../address.types";
import "./AddressFieldControl.sass";
import { env } from "../../../../utils/env";

export type AddressFieldControlProps = FormFieldProps;

const allFieldsSlugs = [
  "location_address",
  "location_address2",
  "location_city",
  "location_state",
  "location_zip",
  "location_country",
];

export const AddressFieldControl: React.FunctionComponent<AddressFieldControlProps> = ({
  field,
  formItem,
  name,
  label,
  required,
  caption,
  readOnly,
}) => {
  const fieldsToDisplay =
    formItem.params?.display_fields && formItem.params.display_fields.length
      ? formItem.params.display_fields
      : allFieldsSlugs;

  const isMultipleFormat = field.params?.display_format === "multiple";
  const requiredText = $t(validatorText.validationRequired);
  const addressField = useFormField({
    name: `${name}.${AddressFieldTypes.location_address}`,
    validate: validateWithYup(
      yup.string().when({
        is: () => formItem.required && fieldsToDisplay.includes("location_address"),
        then: (s) => s.required(requiredText),
      })
    ),
  });
  const address2Field = useFormField({
    name: `${name}.${AddressFieldTypes.location_address2}`,
  });
  const cityField = useFormField({
    name: `${name}.${AddressFieldTypes.location_city}`,
    validate: validateWithYup(
      yup.string().when({
        is: () =>
          formItem.required && isMultipleFormat && fieldsToDisplay.includes("location_city"),
        then: (s) => s.required(requiredText),
      })
    ),
  });

  const stateField = useFormField({
    name: `${name}.${AddressFieldTypes.location_state}`,
  });
  const zipField = useFormField({
    name: `${name}.${AddressFieldTypes.location_zip}`,
  });
  const countryField = useFormField({
    name: `${name}.${AddressFieldTypes.location_country}`,
  });
  // Hidden fields that will be filled with data from Google Maps API once the user selects
  // an address from the dropdown, when available
  const hiddenLatField = useFormField({
    name: `${name}.${AddressFieldTypes.location_latitude}`,
  });
  const hiddenLngField = useFormField({
    name: `${name}.${AddressFieldTypes.location_longitude}`,
  });

  const { state, errorMessage } = useCombinedFieldState([
    addressField,
    address2Field,
    cityField,
    stateField,
    zipField,
    countryField,
  ]);

  const countryOptions = useMemo(() => {
    return generateTranslatedCountriesListWithCodes();
  }, []);

  // Injects the Google Maps API script into the page
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${env.REACT_APP_GOOGLE_MAPS_API_KEY}&loading=async`,
    libraries: GOOGLE_API_LIBRARIES,
  });

  const handleSelectSuggestion = async (
    suggestion: google.maps.places.AutocompletePrediction,
    field: UseFormFieldResult<string>,
    formFieldType: AddressFieldTypes,
    isMultiple: boolean
  ): Promise<void> => {
    const address: string = suggestion.description;

    if (!isMultiple) {
      // Update the field value to receive the complete address from the suggestion
      field.onChange(address);
      // Update lat and lng values for selected location
      const results = await getGeocode({ address });
      const firstResult = results[0];

      // Update lat and lng values for selected location
      const { lat, lng } = getLatLng(firstResult);
      hiddenLatField.onChange(`${lat}`);
      hiddenLngField.onChange(`${lng}`);

      // Parses the selected result further in order to generate the correct address content
      // for the field.
      const addressComponents = firstResult.address_components;
      const parsedAddressComponents = getParsedAddressComponents(addressComponents, true);
      const parsedFieldValue: AddressFormValue = {
        location_address: parsedAddressComponents.address ?? "",
        location_address2: parsedAddressComponents.address2 ?? "",
        location_city: parsedAddressComponents.city ?? "",
        location_state: parsedAddressComponents.state ?? "",
        location_zip: parsedAddressComponents.zipCode ?? "",
        location_country: parsedAddressComponents.country ?? "",
        location_longitude: `${lng}`,
        location_latitude: `${lat}`,
      };

      addressField.onChange(parsedAddressComponents.address ?? "");
      address2Field.onChange(parsedAddressComponents.address2 ?? "");
      cityField.onChange(parsedAddressComponents.city ?? "");
      stateField.onChange(parsedAddressComponents.state ?? "");
      zipField.onChange(parsedAddressComponents.zipCode ?? "");
      countryField.onChange(parsedAddressComponents.country ?? "");

      field.onChange(addressFieldValueToSysRoot(parsedFieldValue));
    } else {
      const results = await getGeocode({ address });
      const firstResult = results[0];

      // Prepares the Address+Number, City, State, ZIP code, and Country values from the
      // geocode result.
      // Note that depending on the level of the query (address, city, or state) the results
      // may contain data from below or above it's hierarchical level. If they exist, we
      // use them to populate the fields overwriting its previous content.
      const addressComponents = firstResult.address_components;
      const parsedAddressComponents = getParsedAddressComponents(addressComponents);

      // Address can only be overwritten if it exists in the selected option and the
      // formFieldType is AddressFieldTypes.location_address.
      // If editing address, but the selected option does not contain an address, clear
      // the field.
      const newAddress = parsedAddressComponents.address ?? "";
      if (formFieldType === AddressFieldTypes.location_address) {
        addressField.onChange(newAddress);
      }

      // City, State, ZIP code, and Country are cleared in case they were not present in
      // the selected option
      cityField.onChange(parsedAddressComponents.city ?? "");
      stateField.onChange(parsedAddressComponents.state ?? "");
      zipField.onChange(parsedAddressComponents.zipCode ?? "");
      countryField.onChange(parsedAddressComponents.country ?? "");

      // Update lat and lng values for selected location
      const { lat, lng } = getLatLng(firstResult);
      hiddenLatField.onChange(`${lat}`);
      hiddenLngField.onChange(`${lng}`);
    }
  };

  return (
    <FormFieldControl
      label={label}
      caption={caption}
      required={required}
      state={state}
      errorMessage={errorMessage}
      readOnly={readOnly}
    >
      {!isMultipleFormat && (
        <div className="address-field-control__field--full-width">
          <AutocompleteAddressField
            readOnly={readOnly}
            formField={addressField}
            formFieldType={AddressFieldTypes.location_address}
            isLoaded={isLoaded}
            isMultiple={isMultipleFormat}
            placeholder={$t(AddressFieldControlMessages.locationAddressSingleLinePlaceholder)}
            onSelectSuggestion={handleSelectSuggestion}
          />
        </div>
      )}
      {isMultipleFormat && (
        <div className="address-field-control__fields">
          {fieldsToDisplay.includes("location_address") && (
            <div className="address-field-control__field">
              <AutocompleteAddressField
                readOnly={readOnly}
                formField={addressField}
                formFieldType={AddressFieldTypes.location_address}
                isLoaded={isLoaded}
                isMultiple={isMultipleFormat}
                placeholder={$t(AddressFieldControlMessages.locationAddressPlaceholder)}
                onSelectSuggestion={handleSelectSuggestion}
              />
            </div>
          )}
          {fieldsToDisplay.includes("location_address2") && (
            <div className="address-field-control__field">
              <PlainTextField
                readOnly={readOnly}
                name={address2Field.name}
                placeholder={$t(AddressFieldControlMessages.locationAddress2Placeholder)}
                state={address2Field.state}
                value={address2Field.value}
                onChange={address2Field.onChange}
                onBlur={address2Field.onBlur}
                onFocus={address2Field.onFocus}
              />
            </div>
          )}
          {fieldsToDisplay.includes("location_city") && (
            <div className="address-field-control__field">
              <AutocompleteAddressField
                readOnly={readOnly}
                formField={cityField}
                formFieldType={AddressFieldTypes.location_city}
                isLoaded={isLoaded}
                isMultiple={isMultipleFormat}
                placeholder={$t(AddressFieldControlMessages.locationCityPlaceholder)}
                onSelectSuggestion={handleSelectSuggestion}
              />
            </div>
          )}
          {fieldsToDisplay.includes("location_state") && (
            <div className="address-field-control__field">
              <AutocompleteAddressField
                readOnly={readOnly}
                formField={stateField}
                formFieldType={AddressFieldTypes.location_state}
                isLoaded={isLoaded}
                isMultiple={isMultipleFormat}
                placeholder={$t(AddressFieldControlMessages.locationStatePlaceholder)}
                onSelectSuggestion={handleSelectSuggestion}
              />
            </div>
          )}
          {fieldsToDisplay.includes("location_zip") && (
            <div className="address-field-control__field">
              <PlainTextField
                disabled={readOnly}
                name={zipField.name}
                placeholder={$t(AddressFieldControlMessages.locationZipPlaceholder)}
                state={zipField.state}
                value={zipField.value}
                onChange={zipField.onChange}
                onBlur={zipField.onBlur}
                onFocus={zipField.onFocus}
              />
            </div>
          )}
          {fieldsToDisplay.includes("location_country") && (
            <div className="address-field-control__field">
              <KeyValueCustomSelect<"value", "label", FieldChoice>
                options={countryOptions}
                placeholder={$t(AddressFieldControlMessages.locationCountryPlaceholder)}
                placeholderStyles={{
                  color: "inactive",
                  size: "m",
                  weight: "regular",
                }}
                showPlaceholderArrow={!readOnly}
                isReadOnly={readOnly}
                searchPlaceholder={$t(AddressFieldControlMessages.locationCountrySearchPlaceholder)}
                showFilterGreaterThan={4}
                state={countryField.state}
                value={countryField.value}
                onChange={countryField.onChange}
                onChangeFocused={countryField.onChangeFocused}
              />
            </div>
          )}
        </div>
      )}
    </FormFieldControl>
  );
};
