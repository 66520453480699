import { FieldRegistryConfig } from "../../types";
import { type SmartdocDocument } from "./SmartdocFieldControl/Smartdoc";
import { parseHTMLToDoc } from "@smartsuite/smartdoc/lib/esm/helpers/parsers";
import { SmartdocFieldControl } from "./SmartdocFieldControl/SmartdocFieldControl";

export interface SmartdocFieldValue {
  data: SmartdocDocument;
}

export const smartdocFieldConfig: FieldRegistryConfig<SmartdocFieldValue> = {
  icon: "smartdoc",
  control: SmartdocFieldControl,
  prefill: ({ value }) => {
    return {
      data: parseHTMLToDoc(value),
    };
  },
};
