import { KeyValueCustomMultipleSelect, Paragraph, Pill } from "@smartsuite/react-ui/lib";
import { useMemo, useRef } from "react";
import * as yup from "yup";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { useFormField } from "../../../../hooks/useFormField";
import { isMobilePhone } from "../../../../utils/devices";
import { $t } from "../../../../utils/intl";
import { FormFieldProps } from "../../../types";
import { validateWithYup } from "../../../validator";
import { validatorText } from "../../../validator.text";
import { fieldsText } from "../../fields.text";
import { MultipleSelectFieldValue } from "../multiple-select.config";

import "./MultipleSelectFieldControl.sass";
import { FieldChoice } from "@smartsuite/types";

export type MultipleSelectFieldControlProps = FormFieldProps;

export const MultipleSelectFieldControl: React.FunctionComponent<
  MultipleSelectFieldControlProps
> = ({ field, formItem, name, label, required, caption, readOnly }) => {
  const requiredText = $t(validatorText.validationRequired);
  const dummyItemRef = useRef<HTMLSpanElement>(null);
  const formField = useFormField<MultipleSelectFieldValue>({
    name,
    validate: validateWithYup(
      yup
        .array()
        .of(yup.string())
        .when({
          is: () => formItem.required,
          then: (lr) => lr.required(requiredText).min(1, requiredText),
        })
    ),
  });

  // Options can be limited by configuration on the form builder.
  // If enabled, the choices passed in are filtered by the choices selected in the config.
  // If disabled, use either the choices passed by the config of this field type, or the
  // default choices of the actual app field.
  const options = useMemo(() => {
    const baseChoices: FieldChoice[] = field.params?.choices ?? [];
    if (formItem.params?.selections?.enabled ?? false) {
      const selectionChoicesSet = new Set(formItem.params?.selections?.choiceIDs ?? []);
      return baseChoices.filter((choice) => selectionChoicesSet.has(choice.value as string)) ?? [];
    } else {
      return baseChoices;
    }
  }, [field.params?.choices, formItem.params?.selections]);

  // When opening the dropdown, if the user is in a mobile device, try scrolling the dummy
  // item above the dropdown to the top of the page, so they can see options and input while
  // typing in. Selector component does not offer this behavior by default...
  const handleOpenChanged = (opened: boolean): void => {
    if (opened && isMobilePhone()) dummyItemRef.current?.scrollIntoView(true);
  };

  return (
    <FormFieldControl
      label={label}
      caption={caption}
      required={required}
      state={formField.state}
      errorMessage={formField.errorMessage}
      readOnly={readOnly}
    >
      <span ref={dummyItemRef} />
      <KeyValueCustomMultipleSelect<"value", "label", FieldChoice>
        collision={isMobilePhone() ? "none" : "flipfit"}
        onChange={formField.onChange}
        onChangeFocused={formField.onChangeFocused}
        onOpenedChange={handleOpenChanged}
        options={options}
        isReadOnly={readOnly}
        disabled={readOnly}
        placeholder={$t(fieldsText.multipleSelectPlaceholder)}
        renderSelectedOption={(option) => <Pill text={option.label} />}
        renderOption={(option) => (
          <div className="multiple-select-field-control-option">
            <Paragraph tag="div" weight="medium" wordBreak="break-word" color="currentColor">
              {option.label}
            </Paragraph>

            {option.value_help_text && (
              <Paragraph size="xs" color="currentColor">
                {option.value_help_text}
              </Paragraph>
            )}
          </div>
        )}
        searchPlaceholder={$t(fieldsText.multipleSelectSearchOptionPlaceholder)}
        showFilterGreaterThan={4}
        state={formField.state}
        value={formField.value}
        selectListItemsClass="multiple-select-field-control-list"
      />
    </FormFieldControl>
  );
};
