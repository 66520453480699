import { $t } from "../../../utils/intl";
import { handleNumberField } from "../../prefill/helpers";
import { FieldRegistryConfig } from "../../types";
import { fieldsText } from "../fields.text";
import { TextFieldValue } from "../text/text.config";
import { TextFieldControl } from "../text/TextFieldControl/TextFieldControl";

export const percentFieldConfig: FieldRegistryConfig<TextFieldValue> = {
  icon: "percent",
  control: (props) => (
    <TextFieldControl placeholder={$t(fieldsText.percentPlaceholder)} type="number" {...props} />
  ),
  prefill: handleNumberField,
};
