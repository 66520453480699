import { InputGroup, Paragraph, Textarea } from "@smartsuite/react-ui/lib";
import * as yup from "yup";
import { FormFieldControl } from "../../../../components/FormFieldControl/FormFieldControl";
import { useFormField } from "../../../../hooks/useFormField";
import { FormFieldProps } from "../../../types";
import { applyYupDefaults, validateWithYup } from "../../../validator";
import { TextareaFieldValue } from "../textarea.config";

export type TextAreaFieldControlProps = FormFieldProps;

export const TextAreaFieldControl: React.FunctionComponent<TextAreaFieldControlProps> = ({
  field,
  formItem,
  name,
  label,
  required,
  placeholder,
  caption,
  readOnly,
}) => {
  const formField = useFormField<TextareaFieldValue>({
    name,
    validate: validateWithYup(applyYupDefaults(yup.string(), field, formItem)),
  });

  const maxLength = field.params.max_length;
  const acceleratorText = maxLength ? `${formField.value?.length ?? 0} of ${maxLength}` : "";

  return (
    <FormFieldControl
      label={label}
      caption={caption}
      required={required}
      state={formField.state}
      errorMessage={formField.errorMessage}
      readOnly={readOnly}
    >
      <InputGroup state={formField.state}>
        <Textarea
          readOnly={readOnly}
          name={formField.name}
          value={formField.value}
          required={required}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={formField.onChange}
          onBlur={formField.onBlur}
          onFocus={formField.onFocus}
        />
      </InputGroup>
      {acceleratorText && (
        <Paragraph size="xs" color="currentColor" className="text-area-field-control--accelerator">
          {acceleratorText}
        </Paragraph>
      )}
    </FormFieldControl>
  );
};
