import { SimpleRecord } from "@smartsuite/types";
import { Fields } from "../types/fields";
import {
  FormConfig,
  FormConfigApplicationStructureItem,
  FormConfigItem,
  FormItemType,
  Member,
} from "../types/form";
import { fieldRegistryMap } from "../registry/map";

interface FormItem {
  slug: string;
  formLabel: string;
}

const PREFILL_PREFIX = "Prefill_";

const extractFormLabel = (
  item: FormConfigItem,
  applicationStructure: FormConfigApplicationStructureItem[]
): string => {
  return (
    item.params.label ?? applicationStructure.find((field) => field.slug === item.slug)?.label ?? ""
  );
};

const processSectionItems = (
  sectionItems: FormConfigItem[],
  applicationStructure: FormConfigApplicationStructureItem[]
): FormItem[] => {
  return sectionItems.map((item) => ({
    formLabel: extractFormLabel(item, applicationStructure),
    slug: item.slug,
  }));
};

export const isPrefillKey = (key: string): boolean => key.startsWith(PREFILL_PREFIX);
export const extractFieldName = (key: string): string => key.replace(PREFILL_PREFIX, "");

export const isDynamicField = (fieldType: Fields): boolean =>
  fieldType === Fields.linkedrecordfield || fieldType === Fields.userfield;

export const getFormLabelsBySlug = (
  config: FormConfig | undefined,
  applicationStructure: FormConfigApplicationStructureItem[]
): FormItem[] => {
  if (!config) return [];

  return config.form_state.items
    .flatMap((item) => {
      if (item.type === FormItemType.section) {
        return item.params.items
          ? processSectionItems(item.params.items, applicationStructure)
          : [];
      }
      return {
        formLabel: extractFormLabel(item, applicationStructure),
        slug: item.slug,
      };
    })
    .filter(Boolean);
};

export const findFieldSlug = (
  formLabelsBySlug: FormItem[],
  fieldName: string
): string | undefined => {
  return formLabelsBySlug.find((item) => {
    return [item?.formLabel.toLowerCase(), item?.slug].find(
      (value) => value === fieldName.toLowerCase()
    );
  })?.slug;
};

export const normalizePrefillFieldValue = ({
  value,
  field,
  linkedRecords,
  members,
  config,
}: {
  value: string;
  field: FormConfigApplicationStructureItem;
  linkedRecords?: SimpleRecord[];
  members?: Member[];
  config?: FormConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): any => {
  const fieldConfig = fieldRegistryMap[field.field_type];
  if (!fieldConfig?.prefill) return "";

  return fieldConfig.prefill({ value, field, linkedRecords, members, config });
};
