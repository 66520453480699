import { FieldChoice } from "../../../types/form";
import { FieldRegistryConfig } from "../../types";
import { FullNameFieldControl } from "./FullNameFieldControl/FullNameFieldControl";

export interface FullNameFieldValue {
  first_name: string;
  last_name: string;
  middle_name: string;
  title: string;
}

export const fullNameFieldValueToSysRoot = (
  value: FullNameFieldValue,
  fieldChoices?: FieldChoice[]
): string => {
  const titleLabel = fieldChoices?.find((choice) => choice.value === value?.title)?.label;
  return [titleLabel, value?.first_name, value?.middle_name, value?.last_name]
    .filter((part) => !!part)
    .join(" ");
};

export const fullNameFieldConfig: FieldRegistryConfig<FullNameFieldValue> = {
  icon: "full-name",
  control: FullNameFieldControl,
  prefill: ({ value, field }) => {
    const fullNameFields = value.split(",");
    const fullNameFieldsSlugs = ["title", "first_name", "middle_name", "last_name"];
    const displayFields = field?.params.display_fields?.sort(
      (a: string, b: string) => fullNameFieldsSlugs.indexOf(a) - fullNameFieldsSlugs.indexOf(b)
    );

    const titles = field?.params.choices;

    return displayFields?.reduce((acc: Record<string, string>, field: string, index: number) => {
      const fullNameField = fullNameFields[index];

      if (!fullNameField) return acc;

      if (field === "title") {
        const title = titles?.find((title) => title.label === fullNameField);
        if (!title) return acc;

        return { ...acc, title: title.value };
      }

      return { ...acc, [field]: fullNameField };
    }, {});
  },
};
