import * as Sentry from "@sentry/browser";
import { initFieldsLogicRegistry } from "@smartsuite/fields-logic/lib/field-type-registry/field-logic-init";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

import { MockAppRecordsService } from "./registry/fields-logic/fake-app-record-service";
import "./styles.sass";
import { FieldTypesConfig, Member } from "@smartsuite/types";
import { fieldTypes } from "./registry/fields-logic/field-types-map";
import { env } from "./utils/env";

const rate = process.env.NODE_ENV === "production" ? 0.5 : 1.0;

Sentry.init({
  enabled: !!env.REACT_APP_SENTRY_ENVIRONMENT,
  dsn: "https://8ae451a99c4448709c18eaf53b6dbea5@o1324044.ingest.sentry.io/6594505",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: rate,
  replaysOnErrorSampleRate: rate,
  replaysSessionSampleRate: rate,
  environment: env.REACT_APP_SENTRY_ENVIRONMENT,
  release: `forms-web@${process.env.REACT_APP_COMMIT_SHA}`,
  ignoreErrors: [
    "Non-Error exception captured",
    "Non-Error promise rejection captured",
    "Object captured as promise rejection with keys: code, data, message",
    "The operation is insecure.",
  ],
});

// Initializes field logic for limiting visibility of fields and processing values
initFieldsLogicRegistry(
  {} as FieldTypesConfig,
  fieldTypes,
  new MockAppRecordsService({}, null as unknown as Member)
);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
