import { FieldRegistryConfig } from "../../types";
import { SingleSelectFieldValue } from "../single-select/single-select.config";
import { YesNoFieldControl } from "./YesNoFieldControl";

export const yesNoFieldConfig: FieldRegistryConfig<SingleSelectFieldValue> = {
  icon: "yes-and-no",
  control: YesNoFieldControl,
  prefill: ({ value }) => {
    const trueValues = ["yes", "true", "1"];
    return trueValues.includes(value.toLowerCase());
  },
};
